import { BLOCKS } from "@contentful/rich-text-types";
import { Options } from "@contentful/rich-text-react-renderer";
import React, { ReactNode } from "react";
import { JsonContent } from "../../shared/contentful/contentful.types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import "./recollections.scss";

type Props = {
  intro: JsonContent;
  className: string;
};

const renderOptions = (className: string): Options => ({
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node: ReactNode, children: ReactNode) => (
      <p className={className}>{children}</p>
    ),
  },
});

const RecollectionsIntro = ({ intro, className }: Props) => {
  return <>{renderRichText(intro, renderOptions(className))}</>;
};
export default RecollectionsIntro;
