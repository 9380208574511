import { graphql, PageProps } from "gatsby";
import React, { createContext, useEffect, useState } from "react";
import RecollectionNavigation from "../components/recollections/recollectionNavigation/RecollectionNavigation.component";
import { Layout } from "../components/layout/Layout.component";
import RecollectionsIntro from "../components/recollections/RecollectionIntro.component";
import RecollectionsGrid from "../components/recollections/recollections-grid/RecollectionsGrid.component";
import { useResponsive } from "../hooks/useResponsive";
import {
  ContentfulTypenames,
  JsonContent,
  RecollectionBlock,
  RecollectionGrid,
} from "../shared/contentful/contentful.types";
import { PageContext, SEOContent } from "../shared/page.types";

import "../components/recollections/recollections-page.scss";

type Props = {
  pageData: {
    title: string;
    intro: JsonContent;
    seo: SEOContent;
    content: RecollectionGrid[];
  };
  recollectionsGrid: { recollections: RecollectionBlock[] };
};

export const RecollectionContext = createContext<PageContext>({
  title: "",
  children: <></>,
});

const RecollectionsPage = ({ data, location: { href } }: PageProps<Props>) => {
  const {
    pageData: { title, intro, content, seo },
  } = data;

  const recollections = content.find(
    (c) => c.__typename === ContentfulTypenames.RecollectionGrid
  )?.recollections;

  const [contextValue, setContextValue] = useState<PageContext>({
    title: "",
    children: <></>,
  });

  const { isPortrait, isLarge } = useResponsive();
  const displayTitle = isPortrait && !isLarge;

  useEffect(() => {
    setContextValue({
      title,
      children: (
        <RecollectionsIntro intro={intro} className={"desktop-intro"} />
      ),
    });
  }, []);

  const seoProps = { ...seo, href };

  return (
    <RecollectionContext.Provider value={contextValue}>
      <Layout pageNavigation={RecollectionNavigation} seo={seoProps}>
        {displayTitle && (
          <div className="recollections-page__intro-wrapper">
            <RecollectionsIntro
              intro={intro}
              className={"recollections-page__intro"}
            />
          </div>
        )}
        {recollections && (
          <div className="recollections-page__recollections-wrapper">
            <RecollectionsGrid recollections={recollections} />
          </div>
        )}
      </Layout>
    </RecollectionContext.Provider>
  );
};
export default RecollectionsPage;

export const query = graphql`
  query recollectionsPage($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    pageData: contentfulLayoutAllPages(
      identifier: { eq: "page_recollections" }
      node_locale: { eq: $language }
    ) {
      title
      intro {
        raw
      }
      content {
        ...recollectionGrid
      }
      seo {
        ...seo
      }
    }
  }
`;
