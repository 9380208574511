import React from "react";
import { RecollectionBlock } from "../../../shared/contentful/contentful.types";
import RecollectionCard from "../recollection-card/RecollectionCard.component";

type Props = {
  recollections: RecollectionBlock[];
};

//TODO: merge with homepage recos grid
const RecollectionsGrid = ({ recollections }: Props) => {
  if (!recollections) return null;
  return (
    <div className="recollections-grid">
      {recollections.map((recollection, index) => (
        <RecollectionCard key={index} {...recollection} />
      ))}
    </div>
  );
};
export default RecollectionsGrid;
